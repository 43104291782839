




























import { Component, Vue } from 'vue-property-decorator';
import User from '../modules/User';

@Component
export default class PageHeader extends Vue {
  async logout(e: Event) {
    await User.Logout();
    await this.$router.go(0);
  }
}
